<template>
  <component :is="layout"></component>
</template>

<script>
import "vue-loading-overlay/dist/vue-loading.css";
import "vue-select/dist/vue-select.css";

import { mapState } from "vuex";
import LoginService from "./services/loginService";
import LoginLayout from './components/layouts/login';
import IndexLayout from './components/layouts/index';

export default {
  data: () => ({}),
  components : {LoginLayout, IndexLayout},
  created() {
    LoginService.getAuthenticatedUser()
      .then((response) => {
          if(response.data){
             this.$store.commit('SET_LOGIN_USER', response.data);
             this.$store.commit('SET_LAYOUT', 'IndexLayout');
             this.$store.dispatch('getMenu');
          }
      })
      .catch((error) => {
        this.$store.commit('SET_LOGIN_USER', {});
        if (error.response) {
          switch (error.response.status) {
            case 401:
              if (
                this.$route.path !== "/login"
              ) {
                this.$router.push({ path: "/login" });
              }
              break;
          }
        }
      });
  },
  computed: mapState(["layout"]),
};
</script>
