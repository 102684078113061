import Router from 'vue-router'

export default new Router({
    routes: [{
        path: '/',
        name: 'dashboard',
        component: () =>
            import ('./views/dashboard/dashboard')
    },
        {
            path: '/login',
            name: 'login',
            component: () =>
                import ( /* webpackChunkName: "login" */ './views/auth/login/LoginComponent')
        },
        {
            path: '/facturacion/eventos',
            name: 'facturacion.eventos',
            component: () =>
                import ( /* webpackChunkName : "facturacion.eventos" */ './views/facturacion/eventos/eventos.vue')
        },
        {
            path: '/facturacion/eventos/edit/:id',
            name: 'facturacion.eventos.edit',
            component: () =>
                import ( /* webpackChunkName : "facturacion.eventos.edit" */ './views/facturacion/eventos/edit')
        },
        {
            path: '/facturacion/eventos/buscar',
            name: 'facturacion.eventos.buscar',
            component: () =>
                import ( /* webpackChunkName : "facturacion.eventos.buscar" */ './views/facturacion/eventos/list')
        },
        {
            path: '/facturacion/eventos/list',
            name: 'facturacion.eventos.index',
            component: () =>
                import ( /* webpackChunkName : "facturacion.eventos.list" */ './views/facturacion/eventos/grid')
        },
        {
            path: '/rips/generar',
            name: 'rips.generar',
            component: () =>
                import ( /* webpackChunkName : "facturacion.rips" */ './views/facturacion/archivo/generar-rips/generar-rips')
        },
        {
            path: '/facturacion/capita',
            name: 'facturacion.capita',
            component: () =>
                import ( /* webpackChunkName : "facturacion.capita" */ './views/facturacion/capita/capita.vue')
        },
        {
            path: '/facturacion/invoice-list',
            name: 'facturacion.invoiceList',
            component: () =>
                import ( /* webpackChunkName : "facturacion.invoice-list" */ './views/facturacion/relacion/relacion.vue')
        },
        {
            path: '/eps',
            name: 'eps',
            component: () =>
                import ( /* webpackChunkName : "eps.index" */ './views/eps/eps')
        },
        {
            path: '/eps/create',
            name: 'eps.create',
            component: () =>
                import ( /* webpackChunkName : "eps.create" */ './views/eps/create')
        },
        {
            path: '/eps/edit/:id',
            name: 'eps.edit',
            component: () =>
                import ( /* webpackChunkName : "eps.edit" */ './views/eps/edit')
        },
        {
            path: '/contratos-eps/',
            name: 'contratos-eps.index',
            component: () =>
                import ( /* webpackChunkName : "contratos.eps.index" */ './views/eps/contratos/index.vue')
        },
        {
            path: '/contratos-eps/create',
            name: 'contratos-eps.create',
            component: () =>
                import ( /* webpackChunkName : "contratos.eps.create" */ './views/eps/contratos/create.vue')
        },
        {
            path: '/contratos-eps/edit/:id',
            name: 'contratos-eps.edit',
            component: () =>
                import ( /* webpackChunkName : "contratos.eps.edit" */ './views/eps/contratos/edit.vue')
        },
        {
            path: '/usuarios-eventos',
            name: 'usuario.eventos',
            component: () =>
                import ( /* webpackChunkName : "usuarios.eventos" */ './views/UsuariosEvt/ListaUsuariosEvt.vue')
        },
        {
            path: '/editarUsuario/:id',
            name: 'editarUsuarioEvt',
            component: () =>
                import ( /* webpackChunkName: "EditarUsuarioEvt" */ './views/UsuariosEvt/EditarUsuarioEvt.vue')
        },
        {
            path: '/verUsuario/:id',
            name: 'verUsuario',
            component: () =>
                import ( /* webpackChunkName: "verUsuario" */ './views/UsuariosEvt/verUsuario.vue')
        },
        {
            path: '/services',
            name: 'services.index',
            component: () =>
                import ( /*webpackChunkName : "services.index"*/ './views/facturacion/Servicios/index')
        },
        {
            path: '/services/create',
            name: 'services.create',
            component: () =>
                import ( /*webpackChunkName : "services.create"*/ './views/facturacion/Servicios/create')
        },
        {
            path: '/services/edit/:id',
            name: 'services.edit',
            component: () =>
                import ( /*webpackChunkName : "services.edit"*/ './views/facturacion/Servicios/edit')
        },
        {
            path: '/facturacion/reports',
            name: 'facturacion.reports',
            component: () =>
                import ( /*webpackChunkName : "facturacion.reports"*/ './views/facturacion/relacion/RelacionFacturas')
        },
        {
            path: '/historias/perfil/:idUsuario',
            name: 'historias.perfil.usuario',
            component: () =>
                import ('./views/historias/perfil/perfil'),
        },
        {
            path: '/historias-infancia/:id',
            name: 'historias.infancia.create',
            component: () =>
                import ('./views/historias/infancia/create')
        },
        {
            path: '/historia-configuracion/grupo-antecedente',
            name: 'historias.grupo-antecedentes.create',
            component: () =>
                import ('./views/historias/base/baseAntecedente/grupoAntecedente/create')
        },
        {
            path: '/historia-configuracion/antecedente',
            name: 'historias.antecedentes.create',
            component: () =>
                import ('./views/historias/base/baseAntecedente/antecedente/create')
        },
        {
            path: '/usuario-antecedente/:id',
            name: 'usuario.antecedentes',
            component: () =>
                import ('./views/historias/antecedente/index')
        },
        {
            path: '/historia-configuracion/seccion-aiepi',
            name: 'historias.seccion-aiepi.create',
            component: () =>
                import ('./views/historias/base/baseAiepi/seccionAiepi/create')
        },
        {
            path: '/item-secciones-aiepi/:id',
            name: 'items.secciones',
            component: () =>
                import ('./views/historias/base/baseAiepi/itemsAiepi/index')
        },

        {
            path: '/historia-rango-escala-abreviada-desarrollo',
            name: 'rango.escala.abreviada.desarrollo',
            component: () =>
                import ('./views/historias/base/baseEscalaAbreviadaDesarrollo/rango/create')
        },
        {
            path: '/historia-enunciados-escala-abreviada-desarrollo/:id',
            name: 'enunciados.escala.abreviada.desarrollo',
            component: () =>
                import ('./views/historias/base/baseEscalaAbreviadaDesarrollo/enunciados/create')
        },
        {
            path: '/usuario-evaluacion/:id',
            name: 'usuario.evaluacion',
            component: () =>
                import ('./views/historias/base/baseEscalaAbreviadaDesarrollo/index/indexEvaluacion')
        },
        {
            path: '/apgar-familiar/:id',
            name: 'usuario.apgar',
            component: () =>
                import ('./views/historias/base/baseApgarFamiliar/index')
        },
        {
            path: '/apgar-familiar-historial/:id',
            name: 'usuario.apgar.historial',
            component: () =>
                import ('./views/historias/base/baseApgarFamiliar/historialApgar')
        },
        {
            path: '/historias-embarazo/:id',
            name: 'historias.embarazo.create',
            component: () =>
                import ('./views/historias/embarazo/create')
        },
        {
            path: '/historial-historia-clinica/:id',
            name: 'usuario.historial.historias',
            component: () =>
                import ('./views/historias/base/baseHistorialHistoria/historial')
        },
        {
            path: '/historias-infancia-print/:id',
            name: 'historias.infancia.print',
            component: () =>
                import ('./views/historias/infancia/printHistoriaInfancia')
        },
        {
            path: '/historias-embarazo-print/:id',
            name: 'historias.embarazo.print',
            component: () =>
                import ('./views/historias/embarazo/printHistoriaEmbarazo')
        },
        {
            path: '/historias-adolescente/:id',
            name: 'historias.adolescente.create',
            component: () =>
                import ('./views/historias/adolescente/create')
        },
        {
            path: '/historias-adolescente-print/:id',
            name: 'historias.adolescente.print',
            component: () =>
                import ('./views/historias/adolescente/printHistoriaAdolescente')
        },

        {
            path: '/historias-juventud/:id',
            name: 'historias.juventud.create',
            component: () =>
                import ('./views/historias/juventud/create')
        },

        {
            path: '/historias-juventud-print/:id',
            name: 'historias.juventud.print',
            component: () =>
                import ('./views/historias/juventud/printHistoriaJuventud')
        },

        {
            path: '/historias-adulto/:id',
            name: 'historias.adulto.create',
            component: () =>
                import ('./views/historias/adulto/create')
        },

        {
            path: '/historias-adulto-print/:id',
            name: 'historias.adulto.print',
            component: () =>
                import ('./views/historias/adulto/printHistoriaAdulto')
        },
        {
            path: '/historias-vejez/:id',
            name: 'historias.vejez.create',
            component: () =>
                import ('./views/historias/vejez/create')
        },
        {
            path: '/historias-vejez-print/:id',
            name: 'historias.vejez.print',
            component: () =>
                import ('./views/historias/vejez/printHistoriaVejez')
        },
        {
            path: '/historias-urgencia/:id',
            name: 'historias.urgencia.create',
            component: () =>
                import ('./views/historias/urgencia/create')
        },
        {
            path: '/historias-morbiilidad/:id',
            name: 'historias.morbilidad.create',
            component: () =>
                import ('./views/historias/morbilidad/create')
        },
        {
            path: '/historias-odontologia/:id',
            name: 'historias.odontologia.create',
            component: () =>
                import ('./views/historias/odontologia/create')
        },
        {
            path: '/historias-higiene-oral/:id',
            name: 'historias.higieneOral.create',
            component: () =>
                import ('./views/historias/higieneOral/create')
        },
        {
            path: '/historias-violencia-sexual/:id',
            name: 'historias.violenciaSexual.create',
            component: () =>
                import ('./views/historias/violenciaSexual/create')
        },
        {
            path: '/historias-psicologia/:id',
            name: 'historias.psicologia.create',
            component: () =>
                import ('./views/historias/psicologia/create')
        },
        {
            path: '/ver-evoluciones-urgencia/:id',
            name: 'ver.evoluciones.urgencia',
            component: () =>
                import ('./views/historias/base/baseEvolucion/show')
        },
        {
            path: '/ver-notas-enfermeria/:id',
            name: 'ver.notas.enfermeria',
            component: () =>
                import ('./views/historias/base/baseNotasEnfermeria/show')
        },

        {
            path: '/historias-cerebro-vascular/:id',
            name: 'historias.cerebrovascular.create',
            component: () =>
                import ('./views/historias/cerebroVascular/create')
        },
        {
            path: '/historias-planificacion-familiar/:id',
            name: 'historias.planificacionfamiliar.create',
            component: () =>
                import ('./views/historias/planificacionFamiliar/create')
        },
        {
            path: '/historias-citologia/:id',
            name: 'historias.citologia.create',
            component: () =>
                import ('./views/historias/citologia/create')
        },
        {
            path: '/historias-citologia-resultado/:id',
            name: 'historias.citologia.resultado.create',
            component: () =>
                import ('./views/historias/citologia/resultado')
        },
        {
            path: '/orden-clinica/:id_orden/:id_usuario',
            name: 'orden.clinica',
            component: () =>
                import ('./views/historias/base/orden/show')
        },
        {
            path: '/formula-clinica/:id_formula/:id_usuario',
            name: 'formula.clinica',
            component: () =>
                import ('./views/historias/base/formulaMedica/show')
        },
        {
            path: '/ordenamiento/:id',
            name: 'ordenamiento',
            component: () =>
                import ('./views/historias/base/baseOrdenamiento/create')
        },
        {
            path: '/evoluciones-usuario/:id_historia',
            name: 'evoluciones.usuario',
            component: () =>
                import ('./views/historias/base/baseEvolucion/evolucionPerfil')
        },
        {
            path: '/formulas-usuario/:id_historia',
            name: 'formulas.usuario',
            component: () =>
                import ('./views/historias/base/formulaMedica/formulaUrgencia')
        },
        {
            path: '/ordenes-usuario/:id_historia',
            name: 'ordenes.usuario',
            component: () =>
                import ('./views/historias/base/orden/ordenesUrgencia')
        },
        {
            path: '/ordenes-medicamento-usuario/:id_historia',
            name: 'ordenes.medicamento.urgencia.usuario',
            component: () =>
                import ('./views/historias/base/orden/ordenesMedicamentoUrgencia')
        },
        {
            path: '/configuracion-formularios',
            name: 'configuracion.formularios',
            component: () =>
                import ('./views/historias/base/configFormularios/index')
        },
        {
            path: '/secciones-formularios/:id',
            name: 'secciones.formulario',
            component: () =>
                import ('./views/historias/base/configFormularios/secciones/index')
        },
        {
            path: '/PAI/:idUsuario',
            name: 'pail.perfil.usuario',
            component: () =>
                import ('./views/PAI/perfilPai'),
        },
        {
            path: '/registro-padre/:idUsuario',
            name: 'registro.padre',
            component: () =>
                import ('./views/PAI/registroPadre'),
        },
        {
            path: '/registroPAI/index',
            name: 'pai.index',
            component: () =>
                import ('./views/PAI/index'),
        },
        {
            path: '/configuracion-vacunas',
            name: 'configuracion.vacunas',
            component: () =>
                import ('./views/historias/base/config-vacunas/index')
        },
        {
            path: '/ingreso-vacunas/create',
            name: 'ingreso.vacunas.create',
            component: () =>
                import ('./views/Inventario/ingreso-vacunas/create')
        },
        {
            path: '/ingreso-vacunas/index',
            name: 'ingreso.vacunas.index',
            component: () =>
                import ('./views/Inventario/ingreso-vacunas/index')
        },
        {
            path: '/traslado-vacunas/create',
            name: 'traslado.vacunas.create',
            component: () =>
                import ('./views/Inventario/traslado-vacunas/create')
        },
        {
            path: '/traslado-vacunas/index',
            name: 'traslado.vacunas.index',
            component: () =>
                import ('./views/Inventario/traslado-vacunas/index')
        },
        {
            path: '/vacunas/reportes',
            name: 'vacunas.reportes',
            component: () =>
                import ('./views/PAI/Reports/index'),
        },
        {
            path: '/configuracion-partes-sistemas',
            name: 'configuracion.partes.sistemas.index',
            component: () =>
                import ('./views/historias/base/BaseConfigPartesSistemas/index')
        },
        {
            path: '/configuracion-tipos-historia',
            name: 'configuracion.tipos.historia.index',
            component: () =>
                import ('./views/historias/base/BaseConfigTipoHistoria/index')
        },
        {
            path: '/configuracion-crud-general',
            name: 'configuracion.crud.general.index',
            component: () =>
                import ('./views/historias/base/configuracionCrudGeneral/index')
        },
        {
            path: '/configuracion-partes-fisicas',
            name: 'configuracion.partes.fisicas.index',
            component: () =>
                import ('./views/historias/base/BaseConfigPartesFisicas/index')
        },
        {
            path: '/configuracion-partes-examen-pies',
            name: 'configuracion.partes.examen.pies.index',
            component: () =>
                import ('./views/historias/base/BaseConfigPartesExamenPies/index')
        },
        {
            path: '/areas-laboratorio',
            name: 'areas-laboratorio.index',
            component: () =>
                import ('./views/historias/base/baseConfigLaboratorio/areas/index')
        },
        {
            path: '/examen-laboratorio/:id_area/:id_examen?',
            name: 'examen-laboratorio.index',
            component: () =>
                import ('./views/historias/base/baseConfigLaboratorio/examenes/index')
        },
        {
            path: '/ordenes-trabajo',
            name: 'orden.trabajo.index',
            component: () =>
            import ('./views/historias/base/baseOrdenTrabajo/index')
          },
          {
            path: '/resultados-laboratorio/create',
            name: 'resultados.laboratorio.create',
            component: () =>
            import ('./views/historias/base/resultadosLaboratorios/create')
          },
          {
            path: '/asignacion-areas-laboratorio/index',
            name: 'asignacion.areas.laboratorio.index',
            component: () =>
            import ('./views/historias/base/baseConfigLaboratorio/areas/ConfigAsignacionAreas/index')
          },
          {
            path: '/asignacion-areas/:id_asignacion_area/:id?',
            name: 'asignacion-areas.index',
            component: () =>
            import ('./views/historias/base/baseConfigLaboratorio/areas/ConfigAsignacionAreas/asignacionAreasDetalle/index')
          },
          {
            path: '/resultados/resultadosExamenProfesional',
            name: 'resultados.examenes.profesional.resultadosExamenProfesional',
            component: () =>
            import ('./views/historias/base/baseConfigLaboratorio/resultadosExamenesProfesional/resultadosExamenProfesional')
          },
          {
            path: '/resultados-ordenes',
            name: 'resultado.ordenes.index',
            component: () =>
            import ('./views/historias/base/baseOrdenTrabajo/ordenTrabajoResultados')
          },
          {
            path: '/configuracion-historias',
            name: 'configuracion.historias',
            component: () =>
            import ('./views/historias/configuracionHistoria/historiaClinicaConfig')
          },
          
        {
            path: '/search-user-hc',
            name: 'search.user.historia',
            component: () =>
                import ('./views/historias/perfil/searchUserToHc')
        },
        {
            path: '/seleccionar-sede',
            name: 'select.campus',
            component: () =>
                import ('./views/auth/selectCampus/selectCampus')
        }
    ],
    mode: "history"
});
