<template>
  <div class=" align-items-center">
      <router-view></router-view>
      <!-- <p class="text-muted d-block">&copy; 2019 - {{moment().year()}}</p> -->
  </div>
</template>
<script>
import "./../../assets/vendor/sb-admin-pro/dist/css/styles.css"
export default {

}
</script>
